import React from "react";
import { Box } from "theme-ui";

export default (Component) => () => (
  <Box sx={styles.container}>
    <Component/>
  </Box>
);

const styles = {
  container: {
    textAlign: 'center',
    maxWidth: '1140px',
    py: "20px",
    mx: "auto",
    h2: {
      "&:first-of-type": {
        mb: "0",
      },
      "&:nth-of-type(2)": {
        mt: "0",
      },
      fontSize: '2rem',
      lineHeight: 1.3,
    }
  }
};