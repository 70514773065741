import React from "react";
import { Helmet } from "react-helmet";
import WithPrivacyPolicyPG from "../pagesComponents/WithPrivacyPolicyPG";

const PrivacyPolicy = () => (
  <>
    <Helmet
      meta={[
        {
          name: "robots",
          content: "noindex, nofollow, noarchive, noimageindex",
        },
        {
          name: "googlebot",
          content: "noindex, nofollow, noarchive, noimageindex",
        },
      ]}
    />
    <h2>PRIVACY POLICY</h2>
    <h2>PRIVACY STATEMENT</h2>
    <h2>SECTION 1 &#8211; WHAT DO WE DO WITH YOUR INFORMATION?</h2>
    <p>When you buy something from our store, as part of the buying and selling process, we collect the personal information you give us, such as your name, address, and email address.</p>
    <p>When you browse our store, we automatically receive your computer&#8217;s internet protocol (IP) address, which provides us with information that allows us to learn about your browser and operating system.</p>
    <h2>SECTION 2 &#8211; CONSENT</h2>
    <p>When you provide us with personal information to complete a transaction, place an order, arrange for a delivery or return a purchase, we imply that you consent us to contact you.</p>
    <p>If you don&#8217;t want an unfair advantage receiving tips, information about new products, and other updates that may benefit you, you can opt-out of non transactional email by clicking the unsubscribe link in them.</p>
    <h2>SECTION 3 &#8211; DISCLOSURE</h2>
    <p>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</p>
    <h2>SECTION 4 &#8211; THIRD-PARTY SERVICES</h2>
    <p>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.</p>
    <p>However, certain third-party service providers, such as payment gateways and other payment transaction processors, have their own privacy policies in respect to the information we are required to provide to them for your purchase-related transactions.</p>
    <p>For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers.</p>
    <p>In particular, remember that certain providers may be located in or have facilities that are located a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located.</p>
    <p>As an example, if you are located in Canada and your transaction is processed by a payment gateway located in the United States, then your personal information used in completing that transaction may be subject to disclosure under United States legislation, including the Patriot Act.</p>
    <p>When you click on links on our store, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>
    <p>Digistore24 is our reseller, and they receive payment on our behalf. We never have access to your credit card information.</p>
    <p><a href="https://www.digistore24.com/info/privacy/2">Digistore24 Privacy Policy</a></p>
    <p>Once you leave our store’s website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy</p>
    <h2>SECTION 5 &#8211; SECURITY</h2>
    <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>
  </>
);

export default WithPrivacyPolicyPG(PrivacyPolicy);